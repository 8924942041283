(() => {
  // This function will rebuild carousel on tab change
  const handleTabCarousel = () => {
    const tab = document.querySelectorAll(
      '.journey-tab .aaaem-tabs__tab a'
    ) as NodeListOf<HTMLElement>;
    if (tab.length) {
      tab.forEach(item => {
        item.addEventListener('click', () => {
          const activeTab = document.querySelector(
            '.aaaem-tabs__tabpanel:not([hidden="hidden"])'
          );
          const carouselId = activeTab?.querySelector('.journey__carousel')?.id;
          if (carouselId) {
            const mainCarouselInst = window._tnsInstances?.[carouselId];
            mainCarouselInst?.destroy();
            mainCarouselInst?.rebuild();
          }
        });
      });
    }
  };

  const init = () => {
    handleTabCarousel();
  };
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
