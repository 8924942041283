(() => {
  const handleFaq = ($el: HTMLElement) => {
    let $faqFilterCTAs: NodeListOf<HTMLButtonElement>,
      $faqAllFilterCTA: HTMLButtonElement,
      $faqBlocks: NodeListOf<HTMLButtonElement>,
      $faqExpandToggleCTAs: NodeListOf<HTMLButtonElement>,
      $faqExpandAllCTA: HTMLButtonElement,
      initialTab = 'all',
      filteredFAQClassPrefix = 'show-filtered-faq',
      blockClass = 'faq__block',
      blockActiveClass = 'faq__block--active',
      blockToggleClass = 'faq__filter-cta',
      blockToggleAllClass = 'faq__filter-cta--all',
      blockToggleActiveClass = 'faq__filter-cta--active',
      faqExpandToggleClass = 'faq__inner-toggler',
      faqsExpanded = false;

    // shows all blocks of the FAQ present on the right size
    const showAllBlocks = () => {
      $faqBlocks.forEach($block => {
        $block.classList.add(blockActiveClass);
      });
    };

    // shows a particular block for a given index number
    const showBlock = i => {
      $faqBlocks.forEach(($block, index) => {
        if (i === index) {
          $block.classList.add(blockActiveClass);
        } else {
          $block.classList.remove(blockActiveClass);
        }
      });
    };

    // makes the Block Filtering CTAs active for a givin index number
    const makeBlockCTAActive = i => {
      $faqFilterCTAs.forEach(($btn, index) => {
        if (i === index) {
          $btn.classList.add(blockToggleActiveClass);
        } else {
          $btn.classList.remove(blockToggleActiveClass);
        }
      });
    };

    // updates the text of the expand all CTA based on the no. of expanded headers present in the view
    // when everything is expanded, adds a class faq__toggler--active
    // when everything is collapsed, removes faq__toggler--active class
    const updateExpandAllText = () => {
      const $curHeaders = $el.querySelectorAll(
        '.faq__block--active .aaaem-accordion__header'
      );
      const openedItems = Array.from($curHeaders).filter($header =>
        $header.classList.contains('-ba-is-active')
      );

      if (openedItems.length === $curHeaders.length) {
        $faqExpandAllCTA.classList.add('faq__toggler--active');
        faqsExpanded = true;
      } else if (openedItems.length === 0) {
        $faqExpandAllCTA.classList.remove('faq__toggler--active');
        faqsExpanded = false;
      }
    };

    // appending events
    const appendEvents = () => {
      // when filter buttons on the left are clicked, the right side must be updated with the corresponding content
      $faqFilterCTAs.forEach(($btn, i) => {
        $btn.addEventListener('click', () => {
          $btn.classList.add(blockToggleActiveClass);
          if ($btn.classList.contains(blockToggleAllClass)) {
            showAllBlocks();
          } else {
            // when there is a show all button added, block count will be the index - 1
            showBlock(i + ($faqAllFilterCTA ? -1 : 0));
          }

          // making the corresponding CTA active
          makeBlockCTAActive(i);

          // Change expand all text
          updateExpandAllText();
        });
      });

      if ($faqExpandToggleCTAs?.length) {
        // when expand all button is clicked, click the faq__inner-toggler buttons
        $faqExpandAllCTA.addEventListener('click', () => {
          if (faqsExpanded) {
            $faqExpandToggleCTAs.forEach($cta => {
              if ($cta.classList.contains('js-toggle-on')) {
                $cta.click();
              }
            });
          } else {
            $faqExpandToggleCTAs.forEach($cta => {
              if ($cta.classList.contains('js-toggle-on') !== true) {
                $cta.click();
              }
            });
          }

          updateExpandAllText();
        });
      } else {
        console.warn(
          'Each Accordion should have a expand/collapse all button. Refer to the FAQ kitchen sink'
        );
      }

      // whenever accordion is toggled, updated the expand all text
      window.Bus.on('emu-accordion:toggle', () => {
        updateExpandAllText();
      });
    };

    const initVariables = () => {
      $faqFilterCTAs = $el.querySelectorAll(`.${blockToggleClass}`);
      $faqAllFilterCTA = $el.querySelector(
        `.${blockToggleAllClass}`
      ) as HTMLButtonElement;
      $faqBlocks = $el.querySelectorAll(`.${blockClass}`);
      $faqExpandToggleCTAs = $el.querySelectorAll(`.${faqExpandToggleClass}`);
      $faqExpandAllCTA = $el.querySelector(
        '.faq__toggler'
      ) as HTMLButtonElement;

      try {
        if ($faqFilterCTAs?.length > 1) {
          const $activeFilterEl = $el.closest(`.${filteredFAQClassPrefix}`);
          initialTab = Array.from($activeFilterEl?.classList || [])
            .filter(e => e.indexOf(`${filteredFAQClassPrefix}--`) >= 0)[0]
            ?.replace(`${filteredFAQClassPrefix}--`, '');
        }
      } catch (e) {
        console.warn(e);
      }
    };

    const init = () => {
      if (
        initialTab !== 'all' &&
        isNaN(parseInt(initialTab)) !== true &&
        $faqFilterCTAs?.[parseInt(initialTab) - 1]
      ) {
        const $curCTA = $faqFilterCTAs?.[parseInt(initialTab) - 1];
        $curCTA?.click?.();
        $curCTA
          ?.closest('.button')
          ?.classList.add('faq__filter-page-selected-cta');
      } else {
        if ($faqAllFilterCTA) {
          showAllBlocks();
          $faqAllFilterCTA?.classList.add(blockToggleActiveClass);
        } else {
          showBlock(0);
          makeBlockCTAActive(0);
        }
      }
      updateExpandAllText();
    };

    initVariables();
    appendEvents();
    init();
  };

  const init = () => {
    const $faqs = document.querySelectorAll('.faq') as NodeListOf<HTMLElement>;
    $faqs.forEach($el => {
      handleFaq($el);
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
